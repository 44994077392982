import React, {useEffect ,useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Radio,
  Checkbox,
  FormGroup,
  Paper,
} from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '56.25%',
});

const MenuItem = (props) => {

  const img = props.img ? `https://storage.googleapis.com/tas-images/MenuImgs/${props.img}` : require('../Images/MenuImgs/AlmindligPizza.jpeg');
  const [errorMsg, setErrorMsg] = useState('');

  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedExtras, setSelectedExtras] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionChange = (optionKindId, option) => {
    setSelectedOptions({ ...selectedOptions, [optionKindId]: option });
  };

  const handleExtraChange = (extraKindId, extraId) => {
    const newExtras = { ...selectedExtras };
    if (newExtras[extraKindId]?.includes(extraId)) {
      newExtras[extraKindId] = newExtras[extraKindId].filter((id) => id !== extraId);
    } else {
      newExtras[extraKindId] = [...(newExtras[extraKindId] || []), extraId];
    }
    setSelectedExtras(newExtras);
  };

  const getSelectedExtraObjects = (selectedExtras, extrasKinds) => {
    const selectedExtraObjects = [];

    for (const extraKindId in selectedExtras) {
      selectedExtras[extraKindId].forEach((extraId) => {
        const extra = extrasKinds[extraKindId].extras.find((e) => e.id === extraId);
        if (extra) {
          selectedExtraObjects.push({
            ...extra,
            extraKindId,
            extraKindName: extrasKinds[extraKindId].name,
          });
        }
      });
    }

    return selectedExtraObjects;
  };

  useEffect(() => {
    console.log(props.img);
  },[props.img]);

  const handleSubmit = () => {
    const item = {
      itemId: props.itemId,
      title: props.title,
      basePrice: props.price,
      img: props.img,
      sectionId: props.sectionId,
      options: [],
      extras: [],
      totalItemPrice: 0,
    };

    const missingOptionKinds = props.optionKinds.filter((optionKind) => !selectedOptions.hasOwnProperty(optionKind.id));

    if (missingOptionKinds.length > 0) {
      setErrorMsg(`Husk at vælge: ${missingOptionKinds.map((optionKind) => optionKind.name).join(', ')}`);
      return;
    } else {
      setErrorMsg('');
    }

    for (const optionKindId in selectedOptions) {
      console.log(selectedOptions);
      const option = selectedOptions[optionKindId];

      // Find the corresponding optionKind in the props
      const optionKind = props.optionKinds.find((optionKind) => optionKind.id === parseInt(optionKindId));

      item.options.push({
        optionKindId: optionKindId,
        optionKindName: optionKind.name, // This line should now get the title of the OptionsKind from the props
        optionId: option.id,
        optionTitle: option.title,
        optionPrice: parseFloat(option.price),
      });
    }

    const selectedExtraObjects = getSelectedExtraObjects(selectedExtras, props.extrasKinds);
    selectedExtraObjects.forEach((extra) => {
      item.extras.push({
        extraKindId: extra.extraKindId,
        extraKindName: extra.extraKindName,
        extraId: extra.id,
        extraTitle: extra.title,
        extraPrice: parseFloat(extra.price),
      });
    });

    item.totalItemPrice = calculateTotalItemPrice();

    props.onClickFood(item.totalItemPrice, item);
    handleClose();
  };


  const calculateTotalItemPrice = () => {

    let totalItemPrice = props.price;
    for (const optionKindId in selectedOptions) {
      const selectedOption = selectedOptions[optionKindId];
      totalItemPrice += parseFloat(selectedOption.price);
    }

    for (const extraKindId in selectedExtras) {
      const extras = selectedExtras[extraKindId];
      extras.forEach((extraId) => {
        const extra = props.extrasKinds[extraKindId].extras.find((extra) => extra.id === extraId);
        totalItemPrice += parseFloat(extra.price);
      });
    }

    return totalItemPrice;
  };

  return (
    <>
      <Card onClick={handleClickOpen} sx={{ height: { xs: 'fit-content', md: '12vh' }, display: 'flex', marginBottom: '10px', padding: '20px', paddingLeft: '0px', paddingRight: { xs: '0px', md: '20px' }, paddingBottom: { xs: '0px', md: '20px' }, paddingTop: '0px', boxShadow: 'none', borderTop: '1px #e4e4e4 solid', borderRadius: '0' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <CardContent sx={{ flex: '1 0 auto', paddingLeft: { xs: '0px', md: '20px' } }}>
            <Typography component="div" variant="p" sx={{ 
              fontFeatureSettings: 'kern', 
              WebkitFontSmoothing: 'antialiased', 
              textRendering: 'optimizeLegibility', 
              fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif', 
              fontSize: '1rem' 
            }}>
              {props.title}
            </Typography>
            <Typography color="grey" variant="body2" color="text.secondary" component="div" sx={{ marginBottom: '5px', marginTop: '5px', 
              fontFeatureSettings: 'kern', 
              WebkitFontSmoothing: 'antialiased', 
              textRendering: 'optimizeLegibility', 
              fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif', 
              fontSize: '1rem' 
            }}>
              {props.description}
            </Typography>
            <Typography className="font-link" sx={{color:"#009de0", fontWeight:"bold"}} variant="body2" color="text.secondary" component="div">
              {props.price} kr
            </Typography>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: { xs: 120, md: 'auto' }, height: { xs: '120%', md: '100%' }, borderRadius: '4px', marginLeft: 'auto' }}
          image={img}
          alt="Menu item image"
        />
      </Card>


      <Dialog open={open} onClose={handleClose} sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px', // Set border radius for the Dialog
          },
        }}>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <img
                  src={img}
                  alt={props.title}
                  style={{ width: "100%", objectFit: "cover" }}
                />
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{
                fontFeatureSettings: '"kern", "ss01", "ss05", "ss07"',
                WebkitFontSmoothing: 'antialiased',
                textRendering: 'optimizeLegibility',
                fontFamily: 'WoltHeading-Omnes, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif',
                fontVariantLigatures: 'common-ligatures',
                fontSize: {xs:"1.3rem",sm:'1.7rem'},
                fontWeight: 600,
                fontStyle: 'normal',
                fontStretch: 'normal',
                textTransform: 'none'
              }}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>

          <Typography className="font-link" sx={{color:"#009de0", fontWeight:"bold",fontSize:"1.2rem",marginTop:"2%",marginBottom:"2%"}} variant="body2" color="text.secondary" component="div">
            {props.price} kr
          </Typography>
          <Typography variant="body1" sx={{paddingBottom:"7%",fontSize:"1.2rem"}}>{props.description}</Typography>
          {props.optionKinds.map((optionKind) => (
            <FormControl key={optionKind.id} component="fieldset" fullWidth>
              <FormLabel component="legend" sx={{color:"black", fontWeight:"bold"}}>{optionKind.name}</FormLabel>
              <RadioGroup
                value={selectedOptions[optionKind.id]?.id}
                onChange={(e) => {
                  const selectedOptionId = parseInt(e.target.value);
                  const selectedOption = optionKind.options.find(
                    (option) => option.id === selectedOptionId
                  );
                  handleOptionChange(optionKind.id, selectedOption);
                }}
              >
                {optionKind.options.map((option) => (
                  <Box
                    key={option.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <FormControlLabel
                      value={option.id}
                      control={<Radio />}
                      label=""
                      sx={{ marginRight: 0, marginLeft: 0 }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        paddingLeft: 1,
                      }}
                    >
                      <Typography>{option.title}</Typography>
                      <Typography>+ {option.price} kr</Typography>
                    </Box>
                  </Box>
                ))}

              </RadioGroup>
            </FormControl>
          ))}
          {props.extrasKinds.map((extraKind) => (
            <FormControl key={extraKind.id} component="fieldset" fullWidth>
              <FormLabel component="legend" sx={{color:"black", fontWeight:"bold"}} >{extraKind.name}</FormLabel>
              <FormGroup>
                {extraKind.extras.map((extra) => (
                  <Box key={extra.id} sx={{display:"flex"}}>
                    <FormControlLabel
                      sx={{marginLeft:"0",marginRight:"0"}}
                      control={
                        <Checkbox
                          checked={selectedExtras[extraKind.id]?.includes(extra.id) || false}
                          onChange={() => handleExtraChange(extraKind.id, extra.id)}
                        />
                      }
                      label=""
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        paddingLeft: 1,
                      }}
                    >
                      <Typography>{extra.title}</Typography>
                      <Typography>+ {extra.price} kr</Typography>
                    </Box>
                  </Box>
                ))}
              </FormGroup>
            </FormControl>
          ))}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "transparent",
            display:"block",
          }}
        >
          <IconButton
            edge="end"
            onClick={handleClose}
            aria-label="close"
            sx={{
              color: 'black',
              backgroundColor: 'rgba(23, 15, 15, 0.3)',
              position: "absolute",
              top: "2%",
              right: "6%",
              width: "fit-content",
            }}
          >
            <CloseIcon />
          </IconButton>

          {errorMsg && <Paper className="font-link" style={{ backgroundColor: '#f44336', color: 'white', padding: '1rem', borderRadius: '4px', textAlign: 'center' }}>{errorMsg}</Paper>}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              backgroundColor: "transparent",
            }}
          >
            <Button
              onClick={handleSubmit}
              color="primary"
              sx={{
                backgroundColor: "#009de0",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif',
                fontSize: '1rem',
                fontWeight: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                fontFeatureSettings: '"kern"',
                WebkitFontSmoothing: 'antialiased',
                textRendering: 'optimizeLegibility',
                height: '50px', // Increase button height
                width: '90%', // Set button width to 80% instead of 100%
                color: "white",
                fontWeight: "bold",
                borderRadius: "10px",
                padding: "15px",
                margin: "10px",
              }}
            >
              <Box>
                Add to Order
              </Box>
              <Box>
                {
                  (() => {
                    const price = calculateTotalItemPrice();
                    return (typeof price === 'number' && !isNaN(price)) ? price.toFixed(2) : 'N/A';
                  })()
                } kr
              </Box>

            </Button>
          </Box>

        </DialogActions>
      </Dialog>

    </>
  );
};

export default MenuItem;
